const firebaseConfig = {
    apiKey: "AIzaSyCAJFz3a6HlLTBgGyBAinP51z26PACbbZI",
    authDomain: "calvinwolfelowe-62e60.firebaseapp.com",
    databaseURL: "https://calvinwolfelowe-62e60.firebaseio.com",
    projectId: "calvinwolfelowe-62e60",
    storageBucket: "calvinwolfelowe-62e60.appspot.com",
    messagingSenderId: "693733062459",
    appId: "1:693733062459:web:5395b5f22c6609c85d691e",
    measurementId: "G-C8XQCCVH2R"
};

export default firebaseConfig